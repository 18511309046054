import React from 'react';
import Link from '../link';
import { search } from '../../utils/util';
import More from './more';
import * as styles from './homeJob.module.scss';

const HomeJob = ({ temp, location }) => (
  <div className={styles.homeJob}>
    <p className={styles.title}>{temp.title}</p>
    <ul className={styles.list}>
      {temp.image.map((item, i) => (
        <li key={`${item}${i}`}>
          <Link to={search(temp.content[i])} target="_blank" >
            <img className="yellowHoverUpImg" src={item}></img>
            <p className="job">{temp.content[i]}</p>
          </Link>
        </li>
      ))}
    </ul>
    <More title={temp.more.text} url={temp.more.url} isMargin="true" location={location} />
  </div>
);

export default HomeJob;
